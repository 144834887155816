@use '@angular/material' as mat;

.bg-primary {
    background-color: $primary !important;
}

.text-primary {
    color: $primary !important;
}

.text-danger {
    color: $warn !important;
    font-family: 'Roboto';
}

/* Conteiners */
.container-list {
    overflow-y: auto;
}

.panel-background {
    background-color: $default-card-background;
}

/* Icons NEW / EDIT */
.iconLocal {
    color: $default-label-change-status;
}
  
.localAlert {
    color: $default-label-change-status;
}

.warningArea {
    background-color: $default-label-change-status-background;
}

// Data Table
.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: $default-odd-background; 
   color: $default-data-text;
 }

.table-striped>tbody>tr:nth-child(even)>td, 
.table-striped>tbody>tr:nth-child(even)>th {
   background-color: $default-even-background; 
   color: $default-data-text;
}

 // Menu
.icon-menu-group {
    margin-right: 6px;
    color: $default-icon-color !important;
}

.menu-button {
    text-decoration: none;
    user-select: none;
    color: $default-menu-color;
}

.menu-button:hover, .link-active {
    background-color: $default-menu-highlight !important;
    color: #FFFFFF !important;

    .icon-menu-group {
      color: #FFFFFF !important;
    }
}
  
// generic buttons
// .mat-stroked-button.mat-primary {
//     color: $default-button-color;
// }

.itemSelected {
    background-color: $default-frame-background ; 
    border: 2px solid $default-frame-color; 
    border-radius: 5px;
}

// table-plan
.table-plan {
    background-color: $default-table-plan;
}

.tableItem {
    background-color: $default-table-item-background;
}

.tableItem.selected {
    border: 2px solid $default-table-item-border;
    padding: 0px !important;
}

// menu-plan
.menu-plan {
    background-color: $default-logon-plan;
}

.menuItem {
    background-color: $default-table-item-background;
}

.menuItem.selected {
    border: 2px solid $default-table-item-border;
    padding: 0px !important;
}

// logon-screen
.logon-plan {
    background-color: $default-logon-plan;
}

.logonItem.selected {
    border: 2px solid $default-table-item-border;
    padding: 0px !important;
}

// estate bar
.estate-bar {
    background-color: $default-estate-bar-color;
    color: $default-estate-bar-text;

    .mat-icon:hover {
        color: white !important;
        cursor: pointer;
    }
}

// Tabs
.mat-tab-label {
    background-color: $default-tab-background;
    color: $default-tab-color;
}

.mat-tab-label.mat-tab-label-active, .mat-tab-label.mat-focus-indicator {
    background-color: $default-tab-background;
    color: $default-tab-color;
}
  
// Mass Price Editor
.columnPrice.selected, .rowStyle.selected {
    background-color: $default-selected-background !important; 
}

// pos Devices
.posDevice.active {
    border-top-color: $default-active-border;
    background-color: $default-active-background;
}
  
.posDevice.inactive {
    border-top-color: $default-inactive-border; 
    background-color: $default-inactive-background;
}
  
.mat-menu-panel {
    max-width: none !important;
}

.mat-menu-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}