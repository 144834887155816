@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();

.theme-light {
    $light-primary: mat.define-palette(mat.$light-blue-palette, 800, 600, 900);
    $light-accent: mat.define-palette(mat.$pink-palette, A700, A100, A400);
    $light-warn: mat.define-palette(mat.$red-palette);

    $light-theme: mat.define-light-theme((
      color: (
        primary: $light-primary,
        accent: $light-accent,
        warn: $light-warn,
      )
    ));
    
    @include mat.all-component-themes($light-theme);

    $primary: mat.get-color-from-palette($light-primary, 800);
    $accent: mat.get-color-from-palette($light-accent, A700);
    $warn: mat.get-color-from-palette($light-warn, 600);

    $default-card-background: #FFFFFF;
    $default-label-change-status-background: mat.get-color-from-palette(mat.$grey-palette, 100);
    $default-label-change-status: mat.get-color-from-palette($light-primary, 800);

    // MainBar
    $default-estate-bar-color: mat.get-color-from-palette(mat.$light-blue-palette, 200);
    $default-estate-bar-text: mat.get-color-from-palette(mat.$light-blue-palette, 900);

    // data tables
    $default-odd-background: #FFFFFF;
    $default-even-background: mat.get-color-from-palette(mat.$grey-palette, 300);
    $default-data-text: #000000;

    // menu
    $default-icon-color: $primary;
    $default-menu-color: $primary;
    $default-menu-highlight: $primary;

    //buttons
    $default-button-color: $primary;

    // items
    $default-frame-color: $primary;
    $default-frame-background: mat.get-color-from-palette(mat.$light-blue-palette, 100);

    // table plan
    $default-table-plan: mat.get-color-from-palette(mat.$blue-palette, 100);
    $default-table-item-background: mat.get-color-from-palette(mat.$grey-palette, 300);
    $default-table-item-border: mat.get-color-from-palette(mat.$teal-palette, 600);

    // logon plan
    $default-logon-plan: mat.get-color-from-palette(mat.$teal-palette, 100);
    $default-logon-item-border: mat.get-color-from-palette(mat.$teal-palette, 600);

    // Tabs
    $default-tab-background: #FFFFFF;
    $default-tab-color: mat.get-color-from-palette(mat.$grey-palette, 800);

    // Mass Price Editor
    $default-selected-background: mat.get-color-from-palette(mat.$light-blue-palette, 200);
    $default-selected-border: $primary;

    // Pos Device
    $default-active-border: $primary;
    $default-active-background: #FFFFFF;
    $default-inactive-border: mat.get-color-from-palette(mat.$grey-palette, 600);
    $default-inactive-background: mat.get-color-from-palette(mat.$grey-palette, 300);

    $primary100: mat.get-color-from-palette($light-primary, 100);

    @import 'custom-theme-classes.scss';
}

.theme-dark {
    $dark-primary: mat.define-palette(mat.$light-blue-palette, 800, 600, 900);
    $dark-accent: mat.define-palette(mat.$pink-palette, A700);
    $dark-warn: mat.define-palette(mat.$red-palette);

    $dark-theme: mat.define-dark-theme((
      color: (
        primary: $dark-primary,
        accent: $dark-accent,
        warn: $dark-warn,
      )
    ));
    
    @include mat.all-component-themes($dark-theme);

    $primary: mat.get-color-from-palette($dark-primary, 800);
    $accent: mat.get-color-from-palette($dark-accent, A700);
    $warn: mat.get-color-from-palette($dark-warn, 600);

    $default-card-background: mat.get-color-from-palette(mat.$grey-palette, 800);
    $default-label-change-status-background: mat.get-color-from-palette(mat.$grey-palette, 700);
    $default-label-change-status: mat.get-color-from-palette($dark-primary,400);

    // MainBar
    $default-estate-bar-color: mat.get-color-from-palette(mat.$light-blue-palette, 900);
    $default-estate-bar-text: #FFFFFF;

    // data tables
    $default-odd-background: mat.get-color-from-palette(mat.$grey-palette, 700);
    $default-even-background: mat.get-color-from-palette(mat.$grey-palette, 800);
    $default-data-text: #FFFFFF;

    // menu
    $default-icon-color: #FFFFFF;
    $default-menu-color: #FFFFFF;
    $default-menu-highlight: $accent;

    //buttons
    $default-button-color: $accent;

    // items
    $default-frame-color: $accent;
    $default-frame-background: mat.get-color-from-palette(mat.$pink-palette, 100);
    $default-table-item-border: $accent;

    // table plan
    $default-table-plan: mat.get-color-from-palette(mat.$grey-palette, 800);
    $default-table-item-background: mat.get-color-from-palette(mat.$grey-palette, 900);
    $default-table-item-border: $accent;

    // logon plan
    $default-logon-plan: mat.get-color-from-palette(mat.$grey-palette, 800);
    $default-logon-item-border: $accent;

    // Tabs
    $default-tab-background: mat.get-color-from-palette(mat.$grey-palette, 800);;
    $default-tab-color: #FFFFFF;
    
    // Mass Price Editor
    $default-selected-background: mat.get-color-from-palette(mat.$pink-palette, 200);
    $default-selected-border: mat.get-color-from-palette(mat.$pink-palette, A700);

    // Pos Device
    $default-active-border: $primary;
    $default-active-background: mat.get-color-from-palette(mat.$grey-palette, 800);
    $default-inactive-border: mat.get-color-from-palette(mat.$grey-palette, 600);
    $default-inactive-background: mat.get-color-from-palette(mat.$grey-palette, 300);

    $primary100: mat.get-color-from-palette($dark-primary, 100);

    @import 'custom-theme-classes';
}

$secondary-color: mat.define-palette(mat.$lime-palette);

/* disable outlines for all tags */
button:focus {
  outline: none;
}

/* disable pull-to-refresh action */
body {
    overscroll-behavior-y: contain;
}

.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
        background-color: mat.get-color-from-palette($secondary-color, 200);
    }

    .mat-slide-toggle-thumb {
        background-color: mat.get-color-from-palette($secondary-color, A700);
    }

    .mat-ripple-element {
        background-color: mat.get-color-from-palette($secondary-color, A700);
    }
}

.mat-chip-icon {
    background-color: white;
    font-size: 18px;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    vertical-align: middle;
    text-align: center;
    padding: 3px;
    margin-left: -8px;
}

/* new default style for .mat-optgroup */
.mat-optgroup > .mat-optgroup-label {
  background-color: #c51162 ; /* pink a700 */
  color: white ;
}


/* backgroung image for LoginForm and Password reset */
.loginPattern {
    background-image: url('/assets/Images/white-waves.png');
    min-height: 100vh;
}

.text-success {
    color: mat.get-color-from-palette(mat.$green-palette, 500) !important;
    font-family: 'Roboto';
}

.dataHeader {
    color: mat.get-color-from-palette(mat.$teal-palette, 600);
}

.dataRowHeader {
    background-color: mat.get-color-from-palette(mat.$teal-palette, 600);
    color: white;
    font-weight: normal;
    font-family: 'Roboto';

    .mat-sort-header-arrow {
        color: white !important;
        font-size: inherit;
    }
}

td {
    .mat-checkbox {
        .mat-checkbox-layout {
            margin: 0px !important;
        }
    }
}

/* Widgets */
.widgetTitle {
    font-family : 'Roboto';
    font-size: 18px;
    font-weight: normal;
    margin: 0px;
}

.widgetSubtitle {
    color: mat.get-color-from-palette(mat.$blue-grey-palette, 300);
    font-family: 'Open Sans';
    font-size: 12px;
    margin: 0px 0px 4px 0px !important;
}

.widgetContainer {
    min-height: 350px !important;

    width: 100% !important;
}

.widgetChart {
    height: 350px !important;
    max-height: 350px !important;
}

.zoomWidgetChart {
  height: 70vh !important;
  max-height: 70vh !important;
}

.zoomChart .mat-dialog-container {
    width: 100vw !important;
}

.estateSelector .mat-dialog-container {
    max-width: 100vw !important;
    padding: 12px !important;
}

.estateSelector.cdk-overlay-pane {
    max-width: 100vw !important;
}

.standard-popup {
    min-width: 280px;
}

.customPopup > .mat-dialog-container {
  padding: 0px;

}

.parent-category-popup {
    min-width: 280px;
}

.parent-category-popup .mat-dialog-container {
    padding: 0px !important;
}

.loading-module-card {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}



::ng-deep .mat-select-panel {
  max-height: 60vh;
}

th.mat-sort-header.text-right {
  height: 36px;

  ::ng-deep .mat-sort-header-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: row-reverse;
  }
}

/* Popup for Product Search in product editor */
.search-popup > .mat-dialog-container {
  max-height: 80vh;
  height: 80vh;
  padding: 0px;
}

.zoomOrder .mat-dialog-container {
  padding: 0px;
}

@media (max-width: 480px) {
  .search-popup {
    width: 90vw;
    max-width: 90vw;
  }

  .zoomOrder .mat-dialog-container {
    width: 90vw !important;
  }
  
}

@media (min-width: 481px) and (max-width: 768px) {
  .search-popup {
    width: 70vw;
    max-width: 70vw;
  }

  .zoomOrder .mat-dialog-container {
    width: 70vw !important;
  }
  
}

@media (min-width: 769px) {
  .search-popup {
    width: 50vw;
    max-width: 50vw;
  }

  .zoomOrder .mat-dialog-container {
    width: 50vw !important;
    max-width: 400px;
  }
  
}
/* ****************************************** */
