/* You can add global styles to this file, and also import other style files */


/* Hide step on inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading-overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,115,187,0.2); /* Blue Gardiff with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.report-section-card {
  /* min-height: 600px;
  min-width: 300px; */
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0px;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* It's important for a Botton-toggle-group component
   with this customization in main style sheet we can
   change easily the height property */
.mat-button-toggle-label-content{
  line-height: inherit !important;
}

.invisible-tabs > .mat-tab-header {
  display: none !important;
}
